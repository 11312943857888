<mat-card class="mat-mdc-elevation-specific mat-elevation-z4 gbr-personal-data">
  <div class="master-data">
    <div class="name-container">
      <div class="firstname">
        <h4 class="title">{{ 'gbr.user-infos.firstname' | translate }}</h4>
        <h4>{{ employee?.firstname | uppercase }}</h4>
      </div>
      <div class="lastname">
        <h4 class="title">{{ 'gbr.user-infos.lastname' | translate }}</h4>
        <h4>{{ employee?.lastname | uppercase }}</h4>
      </div>
    </div>
    <div class="main-data">
      <div class="image">
        <img [src]="employeeImage" class="profile-image" *ngIf="employeeImage; else imageDummy" />
        <ng-template #imageDummy>
          <div class="image-dummy">
            <mat-icon>person</mat-icon>
          </div>
        </ng-template>
      </div>
      <div *ngFor="let attribute of masterAttributes" class="data-point" [class]="attribute.attribute">
        <div class="icon">
          <mat-icon>{{ attribute.icon }}</mat-icon>
        </div>
        <p class="title">
          {{ 'gbr.user-infos.' + attribute.title | translate }}
        </p>
        <p class="value" *ngIf="employee">
          {{ attribute.displayFn ? attribute.displayFn(employee[attribute.attribute]) : employee[attribute.attribute] }}
          {{ attribute.attributeSuffix }}
        </p>
      </div>
    </div>
  </div>
  <div class="private-data">
    <div class="personal-data-wrapper">
      <h4 class="title">
        {{ 'gbr.user-infos.personal-data' | translate | uppercase }}
      </h4>
      <div class="personal-data">
        <div class="data-point birthday">
          <div class="icon">
            <mat-icon>event</mat-icon>
          </div>
          <p class="title">{{ 'gbr.user-infos.birthday' | translate }}</p>
          <p class="value">{{ employee?.dateOfBirth | dateTimezoneFix: 'DD.MM.YYYY' }}</p>
        </div>
        <div class="data-point marital-status">
          <div class="icon">
            <mat-icon>groups</mat-icon>
          </div>
          <p class="title">{{ 'gbr.user-infos.marital-status' | translate }}</p>
          <p class="value">{{ 'gbr.user-infos.marital-status-type.' + employee?.maritalStatus | translate }}</p>
        </div>
      </div>
    </div>

    <div class="personal-data-wrapper">
      <h4 class="title">
        {{ 'gbr.user-infos.contact' | translate | uppercase }}
      </h4>
      <div class="personal-data">
        <div class="data-point email">
          <div class="icon">
            <mat-icon>email</mat-icon>
          </div>
          <p class="title">{{ 'gbr.user-infos.email' | translate }}</p>
          <p class="value">{{ employee?.emailAddress }}</p>
        </div>
      </div>
    </div>
  </div>
</mat-card>
