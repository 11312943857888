import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GbrFile } from '@coin/modules/gbr/util';
import { RequestHandlerService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class GbrDocumentsService {
  constructor(
    private http: HttpClient,
    private requestHandler: RequestHandlerService
  ) {}

  public uploadGbrDocument(file: GbrFile): Observable<object> {
    const params = new HttpParams().append('key', file.filePath);
    return this.http.get<{ signedUrl: string }>(`${environment.api.documentsUrl}/gbr/upload`, { params }).pipe(
      switchMap(({ signedUrl }) => this.http.put(signedUrl, file.file)),
      this.requestHandler.handleError(`Error while saving the file: ${file.fileName}`, true)
    );
  }

  public downloadGbrDocument(path: string): Observable<{ signedUrl: string }> {
    return this.http.post<{ signedUrl: string }>(`${environment.api.documentsUrl}/gbr/download`, { key: path }).pipe(tap(({ signedUrl }) => window.open(signedUrl, '_blank')));
  }
}
