<div class="gbr-simple-input-wrapper">
  <mat-card class="mat-mdc-elevation-specific mat-elevation-z4" [class.invalid]="showError">
    <div class="empty">
      <div class="selected" *ngIf="display; else justLabel" @flyInFromBottom>
        <div>
          <p class="label">{{ label | translate }}</p>
          <div [ngSwitch]="type">
            <p *ngSwitchCase="types.DateRange" class="value" [innerHTML]="getDateRangeText(value)"></p>
            <p *ngSwitchCase="types.YesNo" class="value">
              {{ (value ? 'general.yes' : 'general.no') | translate }}
            </p>
            <p *ngSwitchDefault class="value">
              {{ value }}
            </p>
          </div>
        </div>
        <div *ngIf="type === types.DateRange">
          <p class="label">{{ 'gbr.simple-input.range' | translate }}</p>
          <p class="value">{{ getRangeValue(value) }} {{ 'gbr.simple-input.weeks' | translate }}</p>
        </div>
        <div *ngIf="type === types.DateRange">
          <div class="coin-chip-info organization">
            <p class="title">{{ 'gbr.simple-input.grant' | translate }}:</p>
            <p class="value">{{ getGrantValue(value) }} {{ 'gbr.simple-input.days' | translate }}</p>
          </div>
        </div>
      </div>

      <ng-template #justLabel>
        <p class="text">
          {{ label | translate }}
        </p>
        <mat-icon *ngIf="labelTooltip" [matTooltip]="labelTooltip" class="icon-info-gbr">info_outline</mat-icon>
      </ng-template>

      <div style="flex: 1"></div>
    </div>

    <div class="edit" [class.yes-no-input]="type === types.YesNo" *ngIf="!display">
      <coin-input
        *ngIf="type === types.Text"
        svgIcon="edit"
        [placeholder]="'gbr.entry' | translate"
        addClass="shadow"
        [(ngModel)]="value"
        [isNumber]="isNumber"
        [disabled]="disabled"
        (input)="userChanges()">
      </coin-input>

      <coin-dropdown *ngIf="type === types.Dropdown" class="dropdown" [items]="options" [(ngModel)]="value" [disabled]="disabled" (selectionChange)="userChanges()"></coin-dropdown>

      <mat-form-field
        *ngIf="type === types.DateRange"
        class="coin-form-field-outline coin-form-field-outline--shadow coin-form-field-outline--date-range"
        appearance="outline"
        floatLabel="always"
        (click)="picker.open()">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [placeholder]="'gbr.vacation.period-start' | translate" [(ngModel)]="value.start" [disabled]="disabled" (dateChange)="manualChange()" readonly />
          <input matEndDate [placeholder]="'gbr.vacation.period-end' | translate" [(ngModel)]="value.end" [disabled]="disabled" (dateChange)="manualChange()" readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <div *ngIf="type === types.YesNo" class="yes-no-option">
        <div class="yes">
          <coin-checkbox [checked]="value" (onToggle)="toggleYesNo(true)" [disabledSelfToggle]="true" [disabled]="disabled">
            {{ 'general.yes' | translate }}
          </coin-checkbox>
        </div>
        <div class="no">
          <coin-checkbox [checked]="!value" (onToggle)="toggleYesNo(false)" [disabledSelfToggle]="true" [disabled]="disabled">
            {{ 'general.no' | translate }}
          </coin-checkbox>
        </div>
      </div>
    </div>
  </mat-card>
</div>
