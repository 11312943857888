<div class="container">
  <div class="dialog-headline">
    <h3>{{ getTranslationKey('headline') | translate }}</h3>
  </div>
  <div class="dialog-content">
    <div class="explanation-wrapper">
      <div class="head">
        <h5>{{ 'dialog.confirm-checklist.explanation' | translate }}</h5>
      </div>
      <div class="content">
        <p class="explanation">{{ getTranslationKey('explanation') | translate }}</p>
      </div>
    </div>
    <div class="checklist-wrapper">
      <div class="head">
        <h5>{{ 'dialog.confirm-checklist.checklist' | translate }}</h5>
      </div>
      <div class="content">
        <ul class="checklist">
          <li *ngFor="let item of checklist">
            <p>{{ item }}</p>
          </li>
          <div *ngIf="!checklist.length">
            <div class="no-items-warning">
              <mat-icon>warning</mat-icon>
              <span>No checklist items. Please add the first translation:</span>
            </div>
            <p>{{ getTranslationKey('checklist-item-1') }}</p>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <coin-v2-dialog-footer color="white" padding="large">
    <mat-checkbox #checklistAccept
      ><p>{{ 'dialog.confirm-checklist.accept-checklist' | translate }}</p></mat-checkbox
    >
    <div class="v2-footer-spacer"></div>
    <coin-action-button (click)="cancel()" svgIcon="cancel">
      {{ 'general.btnClose' | translate }}
    </coin-action-button>
    <coin-action-button [disabled]="!checklistAccept.checked" (click)="accept()" svgIcon="forward">
      {{ 'general.accept' | translate }}
    </coin-action-button>
  </coin-v2-dialog-footer>
</div>
