<div class="person-view-wrapper" [class.display]="display">
  <div class="info-wrapper">
    <img [src]="'/assets/images/gbr/add-profile-2.png'" />

    <div class="attributes">
      <div class="fill-object"></div>
      <ng-container *ngIf="!isChild; else childInfo">
        <div class="data-point">
          <p class="title">{{ 'gbr.person-input.firstname' | translate }}</p>
          <p class="value">{{ $any(value)?.firstname }}</p>
        </div>
        <div class="data-point">
          <p class="title">{{ 'gbr.person-input.lastname' | translate }}</p>
          <p class="value">{{ $any(value)?.lastname }}</p>
        </div>
        <div class="data-point">
          <div class="icon">
            <mat-icon>room</mat-icon>
          </div>
          <p class="title">{{ 'gbr.person-input.location' | translate }}</p>
          <p class="value">{{ locationDisplay }}</p>
        </div>
        <div class="data-point">
          <div class="icon">
            <mat-icon>explore</mat-icon>
          </div>
          <p class="title">{{ 'gbr.person-input.gid' | translate }}</p>
          <p class="value">{{ $any(value)?.gidOrEmail }}</p>
        </div>
      </ng-container>

      <ng-template #childInfo>
        <div class="data-point">
          <p class="title">{{ 'gbr.person-input.birthday' | translate }}</p>
          <p class="value">{{ getDateText($any(value)?.birthday) }}</p>
        </div>
        <div class="data-point">
          <p class="title">{{ 'gbr.person-input.same-household' | translate }}</p>
          <p class="value">{{ 'gbr.person-input.' + $any(value)?.sameHousehold | translate }}</p>
        </div>
        <div class="data-point">
          <p class="title">{{ 'gbr.person-input.in-education' | translate }}</p>
          <p class="value">{{ 'gbr.person-input.' + $any(value)?.inEducation | translate }}</p>
        </div>
      </ng-template>
    </div>

    <div style="flex: 1"></div>
    <ng-container *ngIf="!display && !disabled">
      <coin-action-button class="primary icon-button" [noicon]="true" (click)="editClick()" >
        <mat-icon>{{ editIcon }}</mat-icon>
      </coin-action-button>
      <coin-action-button class="secondary icon-button delete" [noicon]="true" (click)="removeClick()">
        <mat-icon>delete</mat-icon>
      </coin-action-button>
    </ng-container>
  </div>
</div>
