/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExportUser } from './export-user';
import { ExportStatusMetadataExportRecordFailureDto } from './export-status-metadata-export-record-failure-dto';

export interface ExportStatusMetadata {
  exportedBy?: ExportUser;
  emulatedBy?: ExportUser;
  state?: ExportStatusMetadataStateEnum;
  exportedAt?: string;
  entityId?: string;
  queryFilter?: string | null;
  filename?: string | null;
  failureReason?: string | null;
  readonly discriminator?: string | null;
  readonly failureCount?: number;
  successCount?: number;
  readonly totalCount?: number;
  failures?: Array<ExportStatusMetadataExportRecordFailureDto> | null;
}
export enum ExportStatusMetadataStateEnum {
  Open = 'Open',
  Done = 'Done'
}
