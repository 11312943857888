/**
 * MercerMasterDataAPI
 * API for mercer master data.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dirk.ehrenschwender@nerdware.dev
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ActivityStreamPage } from '../model/activity-stream-page.model';
// @ts-ignore
import { CreateMercerJobCodeAndPositionClassAssignment } from '../model/create-mercer-job-code-and-position-class-assignment.model';
// @ts-ignore
import { EmployeeAssignments } from '../model/employee-assignments.model';
// @ts-ignore
import { EmployeeFieldValuePage } from '../model/employee-field-value-page.model';
// @ts-ignore
import { EmployeeInternalComment } from '../model/employee-internal-comment.model';
// @ts-ignore
import { EmployeeInternalLevel } from '../model/employee-internal-level.model';
// @ts-ignore
import { EmployeePage } from '../model/employee-page.model';
// @ts-ignore
import { JobCodeAssignment } from '../model/job-code-assignment.model';
// @ts-ignore
import { PositionClassAssignment } from '../model/position-class-assignment.model';
// @ts-ignore
import { UpdateJobCodeAssignment } from '../model/update-job-code-assignment.model';
// @ts-ignore
import { UpdatePositionClassAssignment } from '../model/update-position-class-assignment.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateJobCodeAndPositionClassAssignmentRequestParams {
    createMercerJobCodeAndPositionClassAssignment: CreateMercerJobCodeAndPositionClassAssignment;
}

export interface GetActivityStreamRequestParams {
    /** employee id for which the entries are returned */
    employeeId: string;
    pagingPage?: number;
    pagingSize?: number;
}

export interface GetEmployeeFieldValuesRequestParams {
    /** Property for which the field value is to be returned */
    queryTargetProperty?: string;
    queryGid?: string;
    queryLastname?: string;
    queryFirstname?: string;
    queryOrgCode?: string;
    queryOrgSize?: string;
    queryCountry?: string;
    queryAre?: string;
    queryJobCode?: string;
    queryJobCodeValidFrom?: string;
    queryJobCodeValidTo?: string;
    queryFamilyName?: string;
    querySubFamilyName?: string;
    querySpecializationName?: string;
    queryCareerStream?: string;
    queryCareerLevelName?: string;
    queryJobCodeIsValidated?: boolean;
    queryPositionClass?: string;
    queryPositionClassValidFrom?: string;
    queryPositionClassValidTo?: string;
    queryPositionClassIsValidated?: boolean;
    queryInternalLevel?: string;
    pagingPage?: number;
    pagingSize?: number;
}

export interface GetEmployeeInternalCommentRequestParams {
    employeeId: string;
}

export interface GetEmployeeInternalLevelRequestParams {
    employeeId: string;
}

export interface GetEmployeesWithAssignmentsRequestParams {
    /** contains */
    queryGid?: string;
    /** contains */
    queryFirstname?: string;
    /** contains */
    queryLastname?: string;
    /** in list */
    queryOrgCodes?: Array<string>;
    queryOrgSizes?: Array<string>;
    queryCountries?: Array<string>;
    queryAreCodes?: Array<string>;
    queryJobCodes?: Array<string>;
    queryJobCodeValidFrom?: string;
    queryJobCodeValidTo?: string;
    queryJobCodeIsValidated?: boolean;
    queryFamilyNames?: Array<string>;
    querySubFamilyNames?: Array<string>;
    querySpecializationNames?: Array<string>;
    queryCareerStreams?: Array<string>;
    queryCareerLevelNames?: Array<string>;
    queryPositionClasses?: Array<string>;
    queryPositionClassValidFrom?: string;
    queryPositionClassValidTo?: string;
    queryPositionClassIsValidated?: boolean;
    queryInternalLevels?: Array<string>;
    pagingPage?: number;
    pagingSize?: number;
    sortingProperty?: string;
    /** enum: ASC/DESC */
    sortingOrderBy?: string;
}

export interface GetMercerAssignmentsRequestParams {
    /** Employee Gid */
    gid?: string;
}

export interface UpdateEmployeeInternalCommentRequestParams {
    employeeId: string;
    employeeInternalComment: EmployeeInternalComment;
}

export interface UpdateEmployeeInternalLevelRequestParams {
    employeeId: string;
    employeeInternalLevel: EmployeeInternalLevel;
}

export interface UpdateEmployeeJobCodeRequestParams {
    /** Employee ID */
    employeeId: string;
    /** Job Code ID */
    id: string;
    updateJobCodeAssignment: UpdateJobCodeAssignment;
}

export interface UpdateEmployeePositionClassRequestParams {
    /** Employee ID */
    employeeId: string;
    /** Position Class ID */
    id: string;
    updatePositionClassAssignment: UpdatePositionClassAssignment;
}


@Injectable({
  providedIn: 'root'
})
export class MercerMasterDataService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a new Mercer Job Code and Position Class Assignment
     * Create a new Mercer Job Code and Position Class Assignment
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createJobCodeAndPositionClassAssignment(requestParameters: CreateJobCodeAndPositionClassAssignmentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public createJobCodeAndPositionClassAssignment(requestParameters: CreateJobCodeAndPositionClassAssignmentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public createJobCodeAndPositionClassAssignment(requestParameters: CreateJobCodeAndPositionClassAssignmentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public createJobCodeAndPositionClassAssignment(requestParameters: CreateJobCodeAndPositionClassAssignmentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const createMercerJobCodeAndPositionClassAssignment = requestParameters.createMercerJobCodeAndPositionClassAssignment;
        if (createMercerJobCodeAndPositionClassAssignment === null || createMercerJobCodeAndPositionClassAssignment === undefined) {
            throw new Error('Required parameter createMercerJobCodeAndPositionClassAssignment was null or undefined when calling createJobCodeAndPositionClassAssignment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/mercer-job-codes/employees`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createMercerJobCodeAndPositionClassAssignment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get activity stream entries for MercerStanding
     * Returns items from activty stream for specific employee id. Available for line manager of employee, HR of assigned cluster node from employee, C&amp;B Admin and Super Admin.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ActivityStreamPage>;
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ActivityStreamPage>>;
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ActivityStreamPage>>;
    public getActivityStream(requestParameters: GetActivityStreamRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getActivityStream.');
        }
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/mercer-job-codes/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/activity-stream`;
        return this.httpClient.request<ActivityStreamPage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Employee field values
     * Gets the field values for the list view of Employees. 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmployeeFieldValues(requestParameters: GetEmployeeFieldValuesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmployeeFieldValuePage>;
    public getEmployeeFieldValues(requestParameters: GetEmployeeFieldValuesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmployeeFieldValuePage>>;
    public getEmployeeFieldValues(requestParameters: GetEmployeeFieldValuesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmployeeFieldValuePage>>;
    public getEmployeeFieldValues(requestParameters: GetEmployeeFieldValuesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryTargetProperty = requestParameters.queryTargetProperty;
        const queryGid = requestParameters.queryGid;
        const queryLastname = requestParameters.queryLastname;
        const queryFirstname = requestParameters.queryFirstname;
        const queryOrgCode = requestParameters.queryOrgCode;
        const queryOrgSize = requestParameters.queryOrgSize;
        const queryCountry = requestParameters.queryCountry;
        const queryAre = requestParameters.queryAre;
        const queryJobCode = requestParameters.queryJobCode;
        const queryJobCodeValidFrom = requestParameters.queryJobCodeValidFrom;
        const queryJobCodeValidTo = requestParameters.queryJobCodeValidTo;
        const queryFamilyName = requestParameters.queryFamilyName;
        const querySubFamilyName = requestParameters.querySubFamilyName;
        const querySpecializationName = requestParameters.querySpecializationName;
        const queryCareerStream = requestParameters.queryCareerStream;
        const queryCareerLevelName = requestParameters.queryCareerLevelName;
        const queryJobCodeIsValidated = requestParameters.queryJobCodeIsValidated;
        const queryPositionClass = requestParameters.queryPositionClass;
        const queryPositionClassValidFrom = requestParameters.queryPositionClassValidFrom;
        const queryPositionClassValidTo = requestParameters.queryPositionClassValidTo;
        const queryPositionClassIsValidated = requestParameters.queryPositionClassIsValidated;
        const queryInternalLevel = requestParameters.queryInternalLevel;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryTargetProperty, 'query.targetProperty');
        }
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (queryLastname !== undefined && queryLastname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastname, 'query.lastname');
        }
        if (queryFirstname !== undefined && queryFirstname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstname, 'query.firstname');
        }
        if (queryOrgCode !== undefined && queryOrgCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgCode, 'query.orgCode');
        }
        if (queryOrgSize !== undefined && queryOrgSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryOrgSize, 'query.orgSize');
        }
        if (queryCountry !== undefined && queryCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryCountry, 'query.country');
        }
        if (queryAre !== undefined && queryAre !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryAre, 'query.are');
        }
        if (queryJobCode !== undefined && queryJobCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryJobCode, 'query.jobCode');
        }
        if (queryJobCodeValidFrom !== undefined && queryJobCodeValidFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryJobCodeValidFrom, 'query.jobCodeValidFrom');
        }
        if (queryJobCodeValidTo !== undefined && queryJobCodeValidTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryJobCodeValidTo, 'query.jobCodeValidTo');
        }
        if (queryFamilyName !== undefined && queryFamilyName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFamilyName, 'query.familyName');
        }
        if (querySubFamilyName !== undefined && querySubFamilyName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySubFamilyName, 'query.subFamilyName');
        }
        if (querySpecializationName !== undefined && querySpecializationName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>querySpecializationName, 'query.specializationName');
        }
        if (queryCareerStream !== undefined && queryCareerStream !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryCareerStream, 'query.careerStream');
        }
        if (queryCareerLevelName !== undefined && queryCareerLevelName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryCareerLevelName, 'query.careerLevelName');
        }
        if (queryJobCodeIsValidated !== undefined && queryJobCodeIsValidated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryJobCodeIsValidated, 'query.jobCodeIsValidated');
        }
        if (queryPositionClass !== undefined && queryPositionClass !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClass, 'query.positionClass');
        }
        if (queryPositionClassValidFrom !== undefined && queryPositionClassValidFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClassValidFrom, 'query.positionClassValidFrom');
        }
        if (queryPositionClassValidTo !== undefined && queryPositionClassValidTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClassValidTo, 'query.positionClassValidTo');
        }
        if (queryPositionClassIsValidated !== undefined && queryPositionClassIsValidated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClassIsValidated, 'query.positionClassIsValidated');
        }
        if (queryInternalLevel !== undefined && queryInternalLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryInternalLevel, 'query.internalLevel');
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/mercer-job-codes/assignments/field-values`;
        return this.httpClient.request<EmployeeFieldValuePage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get employee internal comment by employee id
     * Get Employee internal comment
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmployeeInternalComment(requestParameters: GetEmployeeInternalCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmployeeInternalComment>;
    public getEmployeeInternalComment(requestParameters: GetEmployeeInternalCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmployeeInternalComment>>;
    public getEmployeeInternalComment(requestParameters: GetEmployeeInternalCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmployeeInternalComment>>;
    public getEmployeeInternalComment(requestParameters: GetEmployeeInternalCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getEmployeeInternalComment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/internal-comment`;
        return this.httpClient.request<EmployeeInternalComment>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get employee internal level by employee id
     * Get Employee internal level
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmployeeInternalLevel(requestParameters: GetEmployeeInternalLevelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmployeeInternalLevel>;
    public getEmployeeInternalLevel(requestParameters: GetEmployeeInternalLevelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmployeeInternalLevel>>;
    public getEmployeeInternalLevel(requestParameters: GetEmployeeInternalLevelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmployeeInternalLevel>>;
    public getEmployeeInternalLevel(requestParameters: GetEmployeeInternalLevelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getEmployeeInternalLevel.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/internal-level`;
        return this.httpClient.request<EmployeeInternalLevel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Employees with assignments
     * Returns all employees who are not terminated and their JobCode and PositionClass if they have been assigned
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmployeesWithAssignments(requestParameters: GetEmployeesWithAssignmentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmployeePage>;
    public getEmployeesWithAssignments(requestParameters: GetEmployeesWithAssignmentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmployeePage>>;
    public getEmployeesWithAssignments(requestParameters: GetEmployeesWithAssignmentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmployeePage>>;
    public getEmployeesWithAssignments(requestParameters: GetEmployeesWithAssignmentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const queryGid = requestParameters.queryGid;
        const queryFirstname = requestParameters.queryFirstname;
        const queryLastname = requestParameters.queryLastname;
        const queryOrgCodes = requestParameters.queryOrgCodes;
        const queryOrgSizes = requestParameters.queryOrgSizes;
        const queryCountries = requestParameters.queryCountries;
        const queryAreCodes = requestParameters.queryAreCodes;
        const queryJobCodes = requestParameters.queryJobCodes;
        const queryJobCodeValidFrom = requestParameters.queryJobCodeValidFrom;
        const queryJobCodeValidTo = requestParameters.queryJobCodeValidTo;
        const queryJobCodeIsValidated = requestParameters.queryJobCodeIsValidated;
        const queryFamilyNames = requestParameters.queryFamilyNames;
        const querySubFamilyNames = requestParameters.querySubFamilyNames;
        const querySpecializationNames = requestParameters.querySpecializationNames;
        const queryCareerStreams = requestParameters.queryCareerStreams;
        const queryCareerLevelNames = requestParameters.queryCareerLevelNames;
        const queryPositionClasses = requestParameters.queryPositionClasses;
        const queryPositionClassValidFrom = requestParameters.queryPositionClassValidFrom;
        const queryPositionClassValidTo = requestParameters.queryPositionClassValidTo;
        const queryPositionClassIsValidated = requestParameters.queryPositionClassIsValidated;
        const queryInternalLevels = requestParameters.queryInternalLevels;
        const pagingPage = requestParameters.pagingPage;
        const pagingSize = requestParameters.pagingSize;
        const sortingProperty = requestParameters.sortingProperty;
        const sortingOrderBy = requestParameters.sortingOrderBy;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryGid !== undefined && queryGid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryGid, 'query.gid');
        }
        if (queryFirstname !== undefined && queryFirstname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryFirstname, 'query.firstname');
        }
        if (queryLastname !== undefined && queryLastname !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryLastname, 'query.lastname');
        }
        if (queryOrgCodes) {
            queryOrgCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgCodes');
            })
        }
        if (queryOrgSizes) {
            queryOrgSizes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.orgSizes');
            })
        }
        if (queryCountries) {
            queryCountries.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.countries');
            })
        }
        if (queryAreCodes) {
            queryAreCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.areCodes');
            })
        }
        if (queryJobCodes) {
            queryJobCodes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.jobCodes');
            })
        }
        if (queryJobCodeValidFrom !== undefined && queryJobCodeValidFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryJobCodeValidFrom, 'query.jobCodeValidFrom');
        }
        if (queryJobCodeValidTo !== undefined && queryJobCodeValidTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryJobCodeValidTo, 'query.jobCodeValidTo');
        }
        if (queryJobCodeIsValidated !== undefined && queryJobCodeIsValidated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryJobCodeIsValidated, 'query.jobCodeIsValidated');
        }
        if (queryFamilyNames) {
            queryFamilyNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.familyNames');
            })
        }
        if (querySubFamilyNames) {
            querySubFamilyNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.subFamilyNames');
            })
        }
        if (querySpecializationNames) {
            querySpecializationNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.specializationNames');
            })
        }
        if (queryCareerStreams) {
            queryCareerStreams.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.careerStreams');
            })
        }
        if (queryCareerLevelNames) {
            queryCareerLevelNames.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.careerLevelNames');
            })
        }
        if (queryPositionClasses) {
            queryPositionClasses.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.positionClasses');
            })
        }
        if (queryPositionClassValidFrom !== undefined && queryPositionClassValidFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClassValidFrom, 'query.positionClassValidFrom');
        }
        if (queryPositionClassValidTo !== undefined && queryPositionClassValidTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClassValidTo, 'query.positionClassValidTo');
        }
        if (queryPositionClassIsValidated !== undefined && queryPositionClassIsValidated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryPositionClassIsValidated, 'query.positionClassIsValidated');
        }
        if (queryInternalLevels) {
            queryInternalLevels.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'query.internalLevels');
            })
        }
        if (pagingPage !== undefined && pagingPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingPage, 'paging.page');
        }
        if (pagingSize !== undefined && pagingSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pagingSize, 'paging.size');
        }
        if (sortingProperty !== undefined && sortingProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingProperty, 'sorting.property');
        }
        if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortingOrderBy, 'sorting.orderBy');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/mercer-job-codes/assignments`;
        return this.httpClient.request<EmployeePage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Mercer Assignments
     * Get Mercer Assignments
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMercerAssignments(requestParameters: GetMercerAssignmentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmployeeAssignments>;
    public getMercerAssignments(requestParameters: GetMercerAssignmentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmployeeAssignments>>;
    public getMercerAssignments(requestParameters: GetMercerAssignmentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmployeeAssignments>>;
    public getMercerAssignments(requestParameters: GetMercerAssignmentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const gid = requestParameters.gid;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (gid !== undefined && gid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>gid, 'gid');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/mercer-job-codes/employees`;
        return this.httpClient.request<EmployeeAssignments>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create and Update employee internal comment
     * Update Employee internal comment
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmployeeInternalComment(requestParameters: UpdateEmployeeInternalCommentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmployeeInternalComment>;
    public updateEmployeeInternalComment(requestParameters: UpdateEmployeeInternalCommentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmployeeInternalComment>>;
    public updateEmployeeInternalComment(requestParameters: UpdateEmployeeInternalCommentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmployeeInternalComment>>;
    public updateEmployeeInternalComment(requestParameters: UpdateEmployeeInternalCommentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling updateEmployeeInternalComment.');
        }
        const employeeInternalComment = requestParameters.employeeInternalComment;
        if (employeeInternalComment === null || employeeInternalComment === undefined) {
            throw new Error('Required parameter employeeInternalComment was null or undefined when calling updateEmployeeInternalComment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/internal-comment`;
        return this.httpClient.request<EmployeeInternalComment>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: employeeInternalComment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update employee internal level
     * Update Employee internal level
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmployeeInternalLevel(requestParameters: UpdateEmployeeInternalLevelRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmployeeInternalLevel>;
    public updateEmployeeInternalLevel(requestParameters: UpdateEmployeeInternalLevelRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmployeeInternalLevel>>;
    public updateEmployeeInternalLevel(requestParameters: UpdateEmployeeInternalLevelRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmployeeInternalLevel>>;
    public updateEmployeeInternalLevel(requestParameters: UpdateEmployeeInternalLevelRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling updateEmployeeInternalLevel.');
        }
        const employeeInternalLevel = requestParameters.employeeInternalLevel;
        if (employeeInternalLevel === null || employeeInternalLevel === undefined) {
            throw new Error('Required parameter employeeInternalLevel was null or undefined when calling updateEmployeeInternalLevel.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/internal-level`;
        return this.httpClient.request<EmployeeInternalLevel>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: employeeInternalLevel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Employee job code
     * Update Employee job code
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmployeeJobCode(requestParameters: UpdateEmployeeJobCodeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<JobCodeAssignment>;
    public updateEmployeeJobCode(requestParameters: UpdateEmployeeJobCodeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<JobCodeAssignment>>;
    public updateEmployeeJobCode(requestParameters: UpdateEmployeeJobCodeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<JobCodeAssignment>>;
    public updateEmployeeJobCode(requestParameters: UpdateEmployeeJobCodeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling updateEmployeeJobCode.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateEmployeeJobCode.');
        }
        const updateJobCodeAssignment = requestParameters.updateJobCodeAssignment;
        if (updateJobCodeAssignment === null || updateJobCodeAssignment === undefined) {
            throw new Error('Required parameter updateJobCodeAssignment was null or undefined when calling updateEmployeeJobCode.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/mercer-job-codes/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/job-code/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<JobCodeAssignment>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateJobCodeAssignment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Employee position class
     * Update Employee position class
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmployeePositionClass(requestParameters: UpdateEmployeePositionClassRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PositionClassAssignment>;
    public updateEmployeePositionClass(requestParameters: UpdateEmployeePositionClassRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PositionClassAssignment>>;
    public updateEmployeePositionClass(requestParameters: UpdateEmployeePositionClassRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PositionClassAssignment>>;
    public updateEmployeePositionClass(requestParameters: UpdateEmployeePositionClassRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const employeeId = requestParameters.employeeId;
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling updateEmployeePositionClass.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateEmployeePositionClass.');
        }
        const updatePositionClassAssignment = requestParameters.updatePositionClassAssignment;
        if (updatePositionClassAssignment === null || updatePositionClassAssignment === undefined) {
            throw new Error('Required parameter updatePositionClassAssignment was null or undefined when calling updateEmployeePositionClass.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/admin/api/v1/master/mercer-job-codes/employee/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/position-class/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<PositionClassAssignment>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updatePositionClassAssignment,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
