import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GbrChildInput, GbrPersonInput, GbrPersonType } from '@coin/modules/gbr/util';
import moment from 'moment';

@Component({
  selector: 'coin-siemens-energy-person-view',
  templateUrl: './person-view.component.html',
  styleUrls: ['./person-view.component.scss', '../../styles/gbr-data-point.scss']
})
export class PersonViewComponent {
  @Input() value: GbrPersonInput | GbrChildInput;
  @Input() type: GbrPersonType;
  @Input() display: boolean;
  @Input() disabled: boolean;
  @Input() showDisabilities = false;
  @Input() editIcon = 'edit';
  @Output() edit = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  get isChild(): boolean {
    return this.type === GbrPersonType.Child;
  }

  get locationDisplay(): string {
    const person = this.value as GbrPersonInput;
    if (!person?.location) {
      return '';
    }
    if (person.location.sameAsEmployee) {
      return 'Gleich';
    }

    return `${person.location.street || ''} ${person.location.number || ''}, ${person.location.postalCode || ''}`;
  }

  constructor() {}

  public editClick(): void {
    this.edit.emit();
  }

  public removeClick(): void {
    this.remove.emit();
  }

  public getDateText(date: string): string {
    return moment(date).format('DD.MM.YYYY');
  }
}
