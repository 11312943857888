<div class="gbr-file-upload-wrapper">
  <mat-card class="mat-mdc-elevation-specific mat-elevation-z4" [class.has-file]="value">
    <div class="select-file" *ngIf="!value; else fileInfo">
      <div class="text">
        <p>{{ 'gbr.file.info' | translate }}</p>
      </div>
      <coin-action-button class="primary" icon="add" (click)="fileUpload.click()">
        {{ 'gbr.add' | translate }}
      </coin-action-button>
      <input hidden type="file" #fileUpload (input)="handleFiles($event.target.files)" />
    </div>

    <ng-template #fileInfo>
      <div class="file-info">
        <div class="data-point">
          <p class="title">{{ 'gbr.file.filename' | translate }}</p>
          <p class="value">{{ value?.fileName }}</p>
        </div>
        <div class="data-point">
          <p class="title">{{ 'gbr.file.type' | translate }}</p>
          <p class="value">{{ getFileType(value) | uppercase }} {{ getFileSize(value) }}</p>
        </div>
        <div style="flex: 1"></div>
        <coin-action-button
          class="secondary icon-button download"
          [noicon]="true"
          (click)="download()"
          [disabled]="!value?.filePath"
          [matTooltip]="!value?.filePath ? ('gbr.file.can-not-download' | translate) : ''">
          <mat-icon>vertical_align_bottom</mat-icon>
        </coin-action-button>
        <coin-action-button class="primary icon-button delete" [noicon]="true" (click)="remove()" *ngIf="!disabled">
          <mat-icon>delete</mat-icon>
        </coin-action-button>
      </div>
    </ng-template>
  </mat-card>
</div>
