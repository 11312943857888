/* eslint-disable max-lines-per-function */
import { BooleanChoice } from '@coin/shared/util-enums';
import { DateHelper } from '@coin/shared/util-helpers';
import * as moment from 'moment';
import { GbrPersonType } from './person-type.enum';
import { GbrChildInput, GbrPersonInput } from './person.interface';
import { GbrTravelRequestDto, TravelPersonDto, TravelRequestFile } from './travel-request-dto.interface';

export interface GbrPersonalInfos {
  email: string;
  isSingleParent: boolean;
  isShiftWorker: boolean;
  hasParentLeave: boolean;
  hasDisabilities: boolean;
  hasCare: boolean;
}

export interface GbrVacationInfos {
  country: string;
  period: {
    start: moment.Moment;
    end: moment.Moment;
  };
}

export interface GbrFile {
  file?: File;
  id?: string;
  fileName?: string;
  filePath?: string;
  fileType?: string;
  sizeInBytes?: number;
}

export class GbrInput {
  personalInfos: GbrPersonalInfos = {} as GbrPersonalInfos;
  vacation: GbrVacationInfos = { period: {} } as GbrVacationInfos;
  spouse: GbrPersonInput = {} as GbrPersonInput;
  children: GbrChildInput[] = [];
  files: GbrFile[] = [];

  constructor(inputValues?: Partial<GbrInput>) {
    if (!inputValues) {
      return;
    }

    this.personalInfos = inputValues.personalInfos;
    this.vacation = inputValues.vacation;
    this.spouse = inputValues.spouse;
    this.children = inputValues.children.filter(child => !!child);
    this.files = inputValues.files.filter(file => !!file);
  }

  public static fromTravelRequestDto(data: GbrTravelRequestDto): GbrInput {
    const gbrInput = new GbrInput();
    gbrInput.setValuesFromDto(data);
    return gbrInput;
  }

  public addFilePath(gid: string, seasonId: string): void {
    for (const file of this.files) {
      file.filePath = `${seasonId}/${gid}/${file.fileName}`;
    }
  }

  public getRequestDto(): Partial<GbrTravelRequestDto> {
    return {
      hasCare: this.personalInfos.hasCare,
      hasDisabilities: this.personalInfos.hasDisabilities,
      isSingleParent: this.personalInfos.isSingleParent,
      isShiftWorker: this.personalInfos.isShiftWorker,
      hasParentLeave: this.personalInfos.hasParentLeave,
      emailAddress: this.personalInfos.email,

      startDate: this.vacation.period?.start?.toISOString(),
      endDate: this.vacation.period?.end?.toISOString(),
      travelCountry: this.vacation.country,

      travelPersons: [this.getSpouseTravelPerson(), ...this.getChildrenTravelPersons()].filter(person => !!person),

      files: this.getFilesDto()
    };
  }

  private getSpouseTravelPerson(): TravelPersonDto {
    if (!this.spouse) {
      return null;
    }

    this.spouse.gidOrEmail = this.spouse.gidOrEmail?.replace(/[＠﹫]/g, '@');
    const isEmail = this.spouse.gidOrEmail?.includes('@');

    return {
      id: this.spouse?.id,
      personType: GbrPersonType.Spouse,
      gid: isEmail || !this.spouse.gidOrEmail ? null : this.spouse.gidOrEmail,
      emailAddress: isEmail ? this.spouse.gidOrEmail : null,
      firstname: this.spouse.firstname,
      lastname: this.spouse.lastname,
      locationSameAsEmployee: this.spouse.location.sameAsEmployee,
      alternatingStreet: this.spouse.location.street,
      alternatingHouseNumber: this.spouse.location.number,
      alternatingZipCode: this.spouse.location.postalCode
    };
  }

  private getChildrenTravelPersons(): TravelPersonDto[] {
    return this.children
      .filter(child => !!child)
      .map(
        child =>
          ({
            id: child?.id,
            personType: GbrPersonType.Child,
            birthdate: child?.birthday?.toISOString(),
            locationSameAsEmployee: child.sameHousehold === BooleanChoice.YES,
            isInEducation: child.inEducation === BooleanChoice.YES
          }) as TravelPersonDto
      );
  }

  private getFilesDto(): TravelRequestFile[] {
    return this.files
      .filter(file => !!file)
      .map(file => ({
        id: file?.id,
        fileName: file.fileName,
        fileType: file.fileType,
        sizeInBytes: file.sizeInBytes,
        filePath: file.filePath
      }));
  }

  public setValuesFromDto(data: GbrTravelRequestDto): void {
    this.personalInfos = {
      isShiftWorker: data.isShiftWorker || false,
      hasDisabilities: data.hasDisabilities || false,
      email: data.emailAddress || '',
      hasParentLeave: data.hasParentLeave || false,
      isSingleParent: data.isSingleParent || false,
      hasCare: data.hasCare || false
    };
    this.vacation = {
      country: data.travelCountry || '',
      period: {
        start: data.startDate !== DateHelper.dbMinDate ? moment.utc(data.startDate) : null,
        end: data.endDate !== DateHelper.dbMinDate ? moment.utc(data.endDate) : null
      }
    };

    const inputSpouse = data.travelPersons?.find(person => person.personType === GbrPersonType.Spouse);
    if (inputSpouse) {
      this.spouse = {
        id: inputSpouse?.id,
        firstname: inputSpouse?.firstname,
        lastname: inputSpouse?.lastname,
        gidOrEmail: inputSpouse?.emailAddress || inputSpouse?.gid,
        location: {
          sameAsEmployee: inputSpouse?.locationSameAsEmployee || false,
          number: inputSpouse?.alternatingHouseNumber,
          street: inputSpouse?.alternatingStreet,
          postalCode: inputSpouse?.alternatingZipCode
        }
      };
    }

    this.children = data.travelPersons
      ?.filter(person => person.personType === GbrPersonType.Child)
      .map(person => ({
        id: person?.id,
        birthday: person.birthdate !== DateHelper.dbMinDate ? moment.utc(person.birthdate) : null,
        sameHousehold: person.locationSameAsEmployee ? BooleanChoice.YES : BooleanChoice.NO,
        inEducation: person.isInEducation ? BooleanChoice.YES : BooleanChoice.NO
      }));

    this.files = data.files.map(file => ({
      id: file.id,
      fileName: file.fileName,
      fileType: file.fileType,
      filePath: file.filePath,
      sizeInBytes: file.sizeInBytes
    }));
  }
}
