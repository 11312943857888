export interface AttributeDisplay {
  icon: string;
  title: string;
  attribute: string;
  attributeSuffix?: string;
  displayFn?: (value: unknown) => string;
}

export const MasterDataConfig: AttributeDisplay[] = [
  {
    icon: 'explore',
    title: 'gid',
    attribute: 'gid'
  },
  {
    icon: 'person',
    title: 'personal-number',
    attribute: 'personnelNumber'
  },
  {
    icon: 'pending_actions',
    title: 'status',
    attribute: 'contractStatus'
  },
  {
    icon: 'account_tree',
    title: 'department',
    attribute: 'orgCode'
  },
  {
    icon: 'room',
    title: 'location',
    attribute: 'locationOffice'
  }
];
