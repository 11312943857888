import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeSeasonOverview, GbrCountryDto, GbrRepresentativeDto, GbrTravelRequestDto } from '@coin/modules/gbr/util';
import { environment } from '@coin/shared/util-environments';
import { ToastrService } from 'ngx-toastr';
import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class GbrService {
  constructor(
    private http: HttpClient,
    private toast: ToastrService
  ) {}

  public getGbrSeasonsByGid(gid: string): Observable<EmployeeSeasonOverview[]> {
    return this.http
      .get<EmployeeSeasonOverview[]>(`${environment.api.baseUrl}/customer/api/master/seasons/gbr/seasons?gid=${gid}`)
      .pipe(this.handleError('Error getting gbr seasons'));
  }

  public getTravelRequest(id: string, showError?: boolean): Observable<GbrTravelRequestDto> {
    return this.http
      .get<GbrTravelRequestDto>(`${environment.api.baseUrl}/customer/api/master/seasons/gbr/${id}`)
      .pipe(this.handleError('Error getting gbr travel request', showError));
  }

  public getTravelRequestByGid(gid: string, seasonId: string, showError?: boolean): Observable<GbrTravelRequestDto> {
    return this.http
      .get<GbrTravelRequestDto>(`${environment.api.baseUrl}/customer/api/master/seasons/gbr?gid=${gid}&seasonId=${seasonId}`)
      .pipe(this.handleError('Error getting gbr travel request', showError));
  }

  public getRepresentatives(seasonid: string, gid: string): Observable<GbrRepresentativeDto> {
    return this.http
      .get<GbrRepresentativeDto>(`${environment.api.baseUrl}/customer/api/master/seasons/gbr/${seasonid}/represented-employees?gid=${gid}`)
      .pipe(this.handleError('Error getting gbr travel request', false));
  }

  public createTravelRequest(travelRequest: GbrTravelRequestDto, showError = true): Observable<GbrTravelRequestDto> {
    return this.http
      .post<GbrTravelRequestDto>(`${environment.api.baseUrl}/customer/api/master/seasons/gbr`, travelRequest)
      .pipe(this.handleError('Error saving gbr travel request', showError), this.showSuccess('Request saved'));
  }

  public updateTravelRequest(travelRequest: GbrTravelRequestDto, showError = true): Observable<GbrTravelRequestDto> {
    return this.http
      .put<GbrTravelRequestDto>(`${environment.api.baseUrl}/customer/api/master/seasons/gbr`, travelRequest)
      .pipe(this.handleError('Error saving gbr travel request', showError), this.showSuccess('Request saved'));
  }

  public getVacationCountryOptions(): Observable<string[]> {
    return this.http.get<GbrCountryDto[]>(`${environment.api.baseUrl}/customer/api/master/seasons/gbr/countries`).pipe(
      map(value => value.map(country => country.countryName)),
      this.handleError('Error loading vacation country options')
    );
  }

  public submitTravelRequest(requestId: string, travelRequest: GbrTravelRequestDto, showError = true): Observable<unknown> {
    return this.http
      .post(`${environment.api.baseUrl}/customer/api/master/seasons/gbr/${requestId}/submit`, travelRequest)
      .pipe(this.handleError('Error submitting gbr travel request', showError), this.showSuccess('Request submitted'));
  }

  public deleteTravelRequest(id: string): Observable<unknown> {
    return this.http
      .delete(`${environment.api.baseUrl}/customer/api/master/seasons/gbr/${id}`)
      .pipe(this.handleError('Error deleting gbr travel request'), this.showSuccess('Request deleted'));
  }

  private showSuccess<T>(message: string): MonoTypeOperatorFunction<T> {
    return tap(() => {
      this.toast.success(message);
    });
  }

  private handleError<T>(message: string, showMessage?: boolean): MonoTypeOperatorFunction<T> {
    return catchError<T, Observable<never>>((res: HttpErrorResponse) => {
      const errorMessage = `${message}. ${res?.error?.reason || 'Unknown'}`;
      if (showMessage) {
        this.toast.error(errorMessage);
      }
      return throwError(errorMessage);
    });
  }
}
