<div class="container gbr-container">
  <div class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <div class="header">
      <h3 class="header-text">{{ 'gbr.request-header' | translate }}</h3>

      <div *ngIf="existingTravelRequest?.requestState" class="coin-chip-info">
        <p class="title">
          {{ 'gbr.state-' + existingTravelRequest.requestState | translate }}
        </p>
        <p class="value">
          {{ existingTravelRequest.updatedAt | date: 'dd.MM.yyyy' }}
        </p>
      </div>

      <div *ngIf="existingTravelRequest?.isEditedByAdmin" class="coin-chip-info">
        <p class="title">
          {{ 'gbr.admin-edit' | translate }}
        </p>
        <p class="value">
          {{ existingTravelRequest?.adminEditDate | date: 'dd.MM.yyyy' }}
        </p>
      </div>

      <coin-simple-lang-switch [includedLangs]="['de', 'en']"></coin-simple-lang-switch>
    </div>

    <div class="loading" *ngIf="loading; else content">
      <app-turbine></app-turbine>
    </div>

    <ng-template #content>
      <div class="main-content">
        <div class="coin-stepper-container">
          <div class="split-view">
            <div class="last-inputs personal-data">
              <div class="profile-infos">
                <coin-siemens-energy-personal-data [employee]="employeeData"></coin-siemens-energy-personal-data>
              </div>
            </div>

            <div class="new-inputs">
              <mat-horizontal-stepper #stepper>
                <form [formGroup]="travelPersons">
                  <div class="new-inputs">
                    <mat-step [completed]="wasRequestSubmitted" [label]="'gbr.overview.step-personal' | translate">
                      <div class="new-inputs">
                        <div class="scroll">
                          <div class="text-container title-part">
                            <h4>
                              {{ 'gbr.overview.step-personal' | translate | uppercase }}
                            </h4>
                            <p>
                              {{ 'gbr.overview.step-personal-description' | translate }}
                            </p>
                          </div>
                          <div class="content-part grid-inputs" formGroupName="personalInfos">
                            <coin-simple-gbr-input [label]="'gbr.personal-infos.single-parent' | translate" [type]="simpleInputTypes.YesNo" formControlName="isSingleParent">
                            </coin-simple-gbr-input>
                            <coin-simple-gbr-input [label]="'gbr.personal-infos.change-shift' | translate" [type]="simpleInputTypes.YesNo" formControlName="isShiftWorker">
                            </coin-simple-gbr-input>
                            <coin-simple-gbr-input
                              [labelTooltip]="'gbr.tooltip.email' | translate"
                              [label]="'gbr.personal-infos.email' | translate"
                              [type]="simpleInputTypes.Text"
                              formControlName="email">
                            </coin-simple-gbr-input>
                            <coin-simple-gbr-input
                              [labelTooltip]="'gbr.tooltip.parent-leave' | translate"
                              [label]="'gbr.personal-infos.parent-leave' | translate"
                              [type]="simpleInputTypes.YesNo"
                              formControlName="hasParentLeave">
                            </coin-simple-gbr-input>
                            <coin-simple-gbr-input [label]="'gbr.personal-infos.disability' | translate" [type]="simpleInputTypes.YesNo" formControlName="hasDisabilities">
                            </coin-simple-gbr-input>
                            <coin-simple-gbr-input
                              [labelTooltip]="'gbr.tooltip.care' | translate"
                              [label]="'gbr.personal-infos.care' | translate"
                              [type]="simpleInputTypes.YesNo"
                              formControlName="hasCare">
                            </coin-simple-gbr-input>
                          </div>
                        </div>
                      </div>
                    </mat-step>
                  </div>

                  <mat-step [completed]="wasRequestSubmitted" [label]="'gbr.overview.step-vacation' | translate">
                    <div class="split-view">
                      <div class="last-inputs">
                        <div class="scroll">
                          <coin-siemens-energy-input-overview [values]="travelPersons" [stepIndex]="1"> </coin-siemens-energy-input-overview>
                        </div>
                      </div>
                      <div class="new-inputs">
                        <div class="scroll">
                          <div class="text-container title-part">
                            <h4>
                              {{ 'gbr.overview.step-vacation' | translate | uppercase }}
                            </h4>
                            <p>
                              {{ 'gbr.overview.step-vacation-description' | translate }}
                            </p>
                          </div>
                          <div class="content-part grid-inputs" formGroupName="vacation">
                            <coin-simple-gbr-input
                              [label]="('gbr.vacation.country' | translate) + '*'"
                              [type]="simpleInputTypes.Dropdown"
                              [options]="countries"
                              formControlName="country">
                            </coin-simple-gbr-input>
                            <coin-simple-gbr-input
                              [labelTooltip]="'gbr.tooltip.period' | translate"
                              [label]="('gbr.vacation.period' | translate) + '*'"
                              [type]="simpleInputTypes.DateRange"
                              formControlName="period">
                            </coin-simple-gbr-input>
                            <ng-container *ngIf="isInvalidPeriod">
                              <p></p>
                              <p class="short-period">{{ 'gbr.period-too-short' | translate }}</p>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-step>

                  <mat-step [completed]="wasRequestSubmitted" [label]="'gbr.overview.step-spouse' | translate">
                    <div class="split-view">
                      <div class="last-inputs">
                        <div class="scroll">
                          <coin-siemens-energy-input-overview [values]="travelPersons" [stepIndex]="2"> </coin-siemens-energy-input-overview>
                        </div>
                      </div>
                      <div class="new-inputs">
                        <div class="travel-person-wrapper">
                          <div class="scroll">
                            <div class="text-container title-part">
                              <h4>
                                {{ 'gbr.overview.partner-title' | translate }}
                              </h4>
                              <p>
                                {{ 'gbr.overview.partner-add-info' | translate }}
                              </p>
                            </div>
                            <div class="content-part">
                              <div class="input-wrapper">
                                <coin-siemens-energy-person-input formControlName="spouse" [type]="personTypes.Spouse" (remove)="removeSpouse()">
                                </coin-siemens-energy-person-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-step>

                  <mat-step [completed]="wasRequestSubmitted" [label]="'gbr.overview.step-children' | translate">
                    <div class="split-view">
                      <div class="last-inputs">
                        <div class="scroll">
                          <coin-siemens-energy-input-overview [values]="travelPersons" [stepIndex]="3"> </coin-siemens-energy-input-overview>
                        </div>
                      </div>
                      <div class="new-inputs">
                        <div class="scroll">
                          <div class="text-container title-part">
                            <h4>
                              {{ 'gbr.overview.step-children' | translate | uppercase }}
                            </h4>
                            <p>
                              {{ 'gbr.overview.step-children-description' | translate }}
                            </p>
                          </div>
                          <div class="content-part">
                            <div class="input-wrapper last-input" formArrayName="children">
                              <div class="input" *ngFor="let child of children.controls; let i = index">
                                <coin-siemens-energy-person-input [formControlName]="i" [type]="personTypes.Child" (remove)="removeChild(i)"> </coin-siemens-energy-person-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-step>

                  <mat-step [completed]="wasRequestSubmitted" [label]="('gbr.overview.step-files' | translate) + '*'">
                    <div class="split-view">
                      <div class="last-inputs">
                        <div class="scroll">
                          <coin-siemens-energy-input-overview [values]="travelPersons" [stepIndex]="4"> </coin-siemens-energy-input-overview>
                        </div>
                      </div>
                      <div class="new-inputs">
                        <div class="scroll">
                          <div class="text-container title-part">
                            <h4>
                              {{ 'gbr.overview.invoice-title' | translate | uppercase }}
                              *
                            </h4>
                            <p>
                              {{ 'gbr.overview.step-files-description' | translate }}
                            </p>
                          </div>
                          <div class="content-part">
                            <div class="input-wrapper last-input" formArrayName="files">
                              <div class="input" *ngFor="let file of files.controls; let i = index">
                                <coin-siemens-energy-file-input [formControlName]="i" (delete)="deleteFile(i)"> </coin-siemens-energy-file-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-step>
                </form>
              </mat-horizontal-stepper>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="buttons">
      <ng-container *ngIf="existingTravelRequest?.id">
        <coin-action-button [disabled]="cannotDelete" class="secondary" [noicon]="true" (click)="delete()" [loading]="loading">
          {{ 'gbr.delete' | translate }}
        </coin-action-button>
        <coin-v2-divider class="divider" orientation="vertical" />
      </ng-container>
      <mat-icon
        *ngIf="wasRequestSubmitted"
        [matTooltip]="('gbr.tooltip.submit-successful' | translate) + ' (' + (existingTravelRequest?.updatedAt | date: 'MM/dd/yyyy') + ')'"
        class="icon-info-gbr green-icon"
        >check_circle</mat-icon
      >
      <coin-action-button *ngIf="!data.isAdmin" class="primary" [noicon]="true" (click)="submit()" [disabled]="!isFormValid" [loading]="loading">
        {{ 'gbr.submit' | translate }}
      </coin-action-button>
      <coin-action-button class="secondary" [noicon]="true" (click)="save()" [disabled]="travelPersons.disabled || !isFormValid" [loading]="loading">
        {{ 'gbr.save' | translate }}
      </coin-action-button>
      <coin-v2-divider class="divider" orientation="vertical" />
      <coin-action-button class="primary" (click)="previous()" arrowPos="left" *ngIf="hasPreviousStep">
        {{ 'gbr.previous-step' | translate }}
      </coin-action-button>
      <coin-action-button class="primary" (click)="next()" *ngIf="hasNextStep">
        {{ 'gbr.next-step' | translate }}
      </coin-action-button>
    </div>
  </div>
</div>
