import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthComponentsModule, AuthGmsAngularClientModule } from '@coin/modules/auth/feature';
import { AuthGmsClientConfig } from '@coin/modules/auth/util';
import { LibGbrModule } from '@coin/modules/gbr/feature';
import { Configuration as ApiConfiguration, ApiModule } from '@coin/shared/data-access';
import { FeatureFlagDirective } from '@coin/shared/data-management-directives';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { NumberFormatCountryIso } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AdminAppState } from './admin-app-state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { Configuration as MercerMasterDataApiConfiguration, ApiModule as MercerMasterDataApiModule } from './master-data/api/generated';
import { CustomTranslationLoader } from './shared/services/custom-translation-loader';
import { SharedModule } from './shared/shared.module';
import { MenuTabsComponent } from './static/header/components/menu-tabs/menu-tabs.component';
import { HeaderComponent } from './static/header/header.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.cmsContentProdBucketCloudfront}translations/admin/`, '.json');
}

registerLocaleData(localeDe, NumberFormatCountryIso.German, localeDeExtra);

@NgModule({
  declarations: [AppComponent, HeaderComponent, MenuTabsComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    LegacyComponentsModule,
    AppRoutingModule,
    AuthComponentsModule,
    AuthGmsAngularClientModule.forRoot({ ...environment.authentication, ...environment.adminAuthentication } as AuthGmsClientConfig),
    NgxsModule.forRoot(AdminAppState, { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    TranslateModule.forRoot(CustomTranslationLoader.config),
    MercerMasterDataApiModule.forRoot(() => {
      return new MercerMasterDataApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    ApiModule.forRoot(() => {
      return new ApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    LibGbrModule,
    FeatureFlagDirective,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWithDelay:5000'
    })
  ],
  providers: [{ provide: LOCALE_ID, useValue: NumberFormatCountryIso.German }, TranslateModule, TranslateService, httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.loadOperatorIcons();
  }

  private addSvgIcon(name: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../assets/svgs/operators/${name}.svg`));
  }

  private loadOperatorIcons(): void {
    ['contains', 'endswith', 'equal', 'greater', 'greaterorequal', 'less', 'lessorequal', 'notequal', 'dropdocuments', 'notstartswith', 'notcontains'].map(iconName =>
      this.addSvgIcon(iconName)
    );
  }
}
