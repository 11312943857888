import { GbrPersonType } from './person-type.enum';

export interface GbrTravelRequestDto {
  id?: string;

  seasonId: string;
  memberId: string;
  employeeId: string;

  isFilledOutByRepresentative: boolean;
  filledOutByRepresentativeUserId: string;

  requestState: TravelRequestState;
  hasAcknowledgedPrivacyData: boolean;
  privacyDataAcknowledgedAt: string;

  hasDisabilities: boolean;
  emailAddress?: string;
  isSingleParent: boolean;
  isShiftWorker: boolean;
  hasParentLeave: boolean;
  hasCare: boolean;
  hasPayoutGrantedLastYear: boolean;

  startDate?: string;
  endDate?: string;
  travelLocation?: string;
  travelCountry?: string;

  travelPersons: TravelPersonDto[];
  files?: TravelRequestFile[];

  createdById?: string;
  createdAt?: string;
  updatedById?: string;
  updatedAt?: string;

  isEditedByAdmin?: boolean;
  adminEditorId?: string;
  adminEditDate?: string;

  evaluationPoints?: number;
  potentialPayout?: number;
  isSelectedForPayout?: boolean;
  isSelectedForPayoutManualOverride?: boolean;
}

export enum TravelRequestState {
  Draft = 'Draft',
  Submitted = 'Submitted'
}

export interface TravelPersonDto {
  id?: string;
  requestId?: string;

  personType: GbrPersonType;

  gid?: string;
  emailAddress?: string;
  firstname?: string;
  lastname?: string;
  locationSameAsEmployee?: boolean;
  alternatingStreet?: string;
  alternatingHouseNumber?: string;
  alternatingZipCode?: string;
  alternatingCity?: string;
  alternatingCountry?: string;

  birthdate?: string;
  isInEducation?: boolean;

  createdById?: string;
  createdAt?: string;
  updatedById?: string;
  updatedAt?: string;
}

export interface TravelRequestFile {
  id?: string;
  requestId?: string;

  fileName: string;
  filePath: string;
  sizeInBytes: number;
  fileType: string;

  createdById?: string;
  createdAt?: string;
  updatedById?: string;
  updatedAt?: string;
}
