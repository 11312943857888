<div class="input-overview-wrapper">
  <ng-container *ngIf="displayValue?.personalInfos">
    <p>{{ 'gbr.overview.step-personal' | translate }}</p>
    <div class="info-grid">
      <coin-simple-gbr-input
        *ngIf="displayValue.personalInfos.isSingleParent !== null"
        [label]="'gbr.personal-infos.single-parent' | translate"
        [display]="true"
        [ngModel]="displayValue.personalInfos.isSingleParent"
        [type]="simpleInputTypes.YesNo">
      </coin-simple-gbr-input>
      <coin-simple-gbr-input
        *ngIf="displayValue.personalInfos.isShiftWorker !== null"
        [label]="'gbr.personal-infos.change-shift' | translate"
        [display]="true"
        [ngModel]="displayValue.personalInfos.isShiftWorker"
        [type]="simpleInputTypes.YesNo">
      </coin-simple-gbr-input>
      <coin-simple-gbr-input
        *ngIf="displayValue.personalInfos.email"
        [label]="'gbr.personal-infos.email' | translate"
        [display]="true"
        [ngModel]="displayValue.personalInfos.email">
      </coin-simple-gbr-input>
      <coin-simple-gbr-input
        *ngIf="displayValue.personalInfos.hasParentLeave !== null"
        [label]="'gbr.personal-infos.parent-leave' | translate"
        [display]="true"
        [ngModel]="displayValue.personalInfos.hasParentLeave"
        [type]="simpleInputTypes.YesNo">
      </coin-simple-gbr-input>
      <coin-simple-gbr-input
        *ngIf="displayValue.personalInfos.hasDisabilities !== null"
        [label]="'gbr.personal-infos.disability' | translate"
        [display]="true"
        [ngModel]="displayValue.personalInfos.hasDisabilities"
        [type]="simpleInputTypes.YesNo">
      </coin-simple-gbr-input>
      <coin-simple-gbr-input
        *ngIf="displayValue.personalInfos.hasCare !== null"
        [label]="'gbr.personal-infos.care' | translate"
        [display]="true"
        [ngModel]="displayValue.personalInfos.hasCare"
        [type]="simpleInputTypes.YesNo">
      </coin-simple-gbr-input>
    </div>
  </ng-container>

  <ng-container *ngIf="displayValue?.vacation && stepIndex > 1">
    <p>{{ 'gbr.overview.step-vacation' | translate }}</p>
    <div class="info-grid">
      <coin-simple-gbr-input *ngIf="displayValue.vacation.country" [label]="'gbr.vacation.country' | translate" [display]="true" [ngModel]="displayValue.vacation.country">
      </coin-simple-gbr-input>
      <coin-simple-gbr-input
        *ngIf="displayValue.vacation?.period?.start"
        [label]="'gbr.vacation.period' | translate"
        [display]="true"
        [ngModel]="displayValue.vacation.period"
        [type]="simpleInputTypes.DateRange"
        class="vacation-period">
      </coin-simple-gbr-input>
    </div>
  </ng-container>

  <ng-container *ngIf="displayValue?.spouse && stepIndex > 2">
    <p>{{ 'gbr.overview.partner-title' | translate }}</p>
    <div class="person">
      <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
        <coin-siemens-energy-person-view *ngIf="displayValue.spouse" [value]="displayValue.spouse" [display]="true"> </coin-siemens-energy-person-view>
      </mat-card>
    </div>
  </ng-container>

  <ng-container *ngIf="displayValue.children?.length && stepIndex > 3">
    <p>{{ 'gbr.overview.minor-children' | translate }}</p>
    <div class="person" *ngFor="let traveler of minorChildren">
      <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
        <coin-siemens-energy-person-view [value]="traveler" [display]="true" [type]="personTypes.Child"> </coin-siemens-energy-person-view>
      </mat-card>
    </div>
    <p>{{ 'gbr.overview.children' | translate }}</p>
    <div class="person" *ngFor="let traveler of adultChildren">
      <mat-card class="mat-mdc-elevation-specific mat-elevation-z4">
        <coin-siemens-energy-person-view [value]="traveler" [display]="true" [type]="personTypes.Child"> </coin-siemens-energy-person-view>
      </mat-card>
    </div>
  </ng-container>
</div>
