import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Employee, EmployeeWithCompensation, EmptyGuid } from '@coin/shared/util-models';
import { environment } from '@coin/shared/util-environments';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Pipe({
  name: 'employeeIdWithCompensation$',
  standalone: true
})
export class EmployeeIdWithCompensationPipe implements PipeTransform {
  constructor(private httpClient: HttpClient) {}

  transform(id: string): Observable<Employee> {
    if (id && id !== EmptyGuid) {
      return this.getEmployeeDetailsWithCompensation(id);
    }
    return of(null);
  }

  // see EmployeeService on Admin Frontend
  public getEmployeeDetailsWithCompensation(id: string): Observable<EmployeeWithCompensation> {
    return this.httpClient.get<EmployeeWithCompensation>(`${environment.api.baseUrl}/admin/api/v3/master/employees/${id}/compensation`).pipe(
      catchError((res: HttpErrorResponse) => {
        return throwError(`Error occured. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }
}
