import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { filter, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GbrChildInput, GbrInput, GbrPersonType } from '@coin/modules/gbr/util';
import { SimpleGbrInputType } from '../../inputs/simple-gbr-input/simple-gbr-input.component';
import { GbrRequestDialogComponent } from '../../gbr-request-dialog.component';

@Component({
  selector: 'coin-siemens-energy-input-overview',
  templateUrl: './input-overview.component.html',
  styleUrls: ['./input-overview.component.scss']
})
export class InputOverviewComponent implements OnInit {
  @Input() values: GbrRequestDialogComponent['travelPersons'];
  @Input() stepIndex = 0;

  public displayValue = new GbrInput();
  public simpleInputTypes = SimpleGbrInputType;
  public personTypes = GbrPersonType;

  constructor(private destroyRef: DestroyRef) {}

  private isMinor(child: GbrChildInput): boolean {
    return moment.utc().diff(child.birthday, 'years') < 18;
  }

  private isAdult(child: GbrChildInput): boolean {
    return moment.utc().diff(child.birthday, 'years') >= 18;
  }

  get minorChildren(): GbrChildInput[] {
    return this.displayValue.children.filter(this.isMinor);
  }

  get adultChildren(): GbrChildInput[] {
    return this.displayValue.children.filter(this.isAdult);
  }

  ngOnInit() {
    this.values?.valueChanges
      .pipe(
        map(value => new GbrInput(value as Partial<GbrInput>)),
        filter(value => !!value)
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.displayValue = value;
      });
  }
}
