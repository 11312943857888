import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@coin/modules/auth/data-access';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { ImporterMetadata, ImportTableFile, S3File } from '@coin/shared/util-models';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { ImporterName } from '../../interfaces/enums/importer-name.enum';

@Injectable()
export class ImporterBaseService {
  constructor(
    private http: HttpClient,
    private toast: ToastrService,
    private loading: LoadingService,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {}

  public upload(file: File, importerName: ImporterName, params?: HttpParams, withTransaction = false, uploadSubPath = 'upload'): Observable<void> {
    this.loading.present();
    return this.http
      .get<{ signedUrl: string }>(`${environment.api.documentsUrl}/importer/${importerName}/${uploadSubPath}/${encodeURIComponent(file.name)}`, {
        params
      })
      .pipe(
        switchMap(({ signedUrl }) => this.http.put<void>(signedUrl, file)),
        tap(() => {
          if (withTransaction) this.toast.info('Starting import...');
          else this.toast.success('Uploaded file');
        }),
        this.httpHelpersService.handleError('Error trying to upload file'),
        finalize(() => this.loading.dismiss())
      );
  }

  public getUploadList(importerName: string): Observable<S3File<ImporterMetadata>[]> {
    return this.http.get<S3File<ImporterMetadata>[]>(`${environment.api.documentsUrl}/importer/${importerName}/list/info`);
  }

  public download(filename: string, importerName: string): Observable<unknown> {
    const params = new HttpParams().append('file', encodeURIComponent(filename));

    this.loading.present();
    return this.http.get<{ signedUrl: string }>(`${environment.api.documentsUrl}/importer/${importerName}/download`, { params }).pipe(
      tap(({ signedUrl }) => window.open(signedUrl, '_blank')),
      this.httpHelpersService.handleError('Error trying to download file'),
      finalize(() => this.loading.dismiss())
    );
  }

  public delete(filename: string, importerName: string): Observable<boolean> {
    this.loading.present();
    return this.http.delete<boolean>(`${environment.api.documentsUrl}/importer/${importerName}/delete/${encodeURIComponent(filename)}`).pipe(
      this.httpHelpersService.handleError('Error trying to delete file'),
      finalize(() => this.loading.dismiss())
    );
  }

  public deleteEmployeeDataFile(file: ImportTableFile, importerName: ImporterName): Observable<{ transactionId: string }> {
    this.loadingService.present();
    return from(this.authService.getActiveUser()).pipe(
      map(user => ({
        fileName: file.name.replace('.xlsx', '.csv'),
        bucket: `${environment.dataImportBucket}/${importerName}/converted`,
        type: 'EmployeeCompensationOverlay',
        deletedByGid: user.gid
      })),
      switchMap(body => this.http.delete<{ transactionId: string }>(`${environment.api.baseUrl}/admin/api/v1/master/importer/compensation-overlay`, { body })),
      tap(() => this.toast.success('Started deletion.')),
      this.httpHelpersService.handleError('Cannot delete employee-data'),
      finalize(() => this.loadingService.dismiss())
    );
  }
}
