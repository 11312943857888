import { Injectable } from '@angular/core';

@Injectable()
export class GbrPersonSaveService {
  public listener: Array<() => void> = [];

  public registerSaveListener(fn: () => void): void {
    this.listener.push(fn);
  }

  public emitSave(): void {
    for (const saveFn of this.listener) {
      try {
        saveFn();
      } catch (err) {
        /* empty */
      }
    }
  }
}
