import { Injectable, OnDestroy, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionCacheService implements OnInit, OnDestroy {
  public readonly timeout = 5 * 60_000;

  private readonly cache = new Map<string, number>();
  private interval: number;

  // TODO: check
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    this.interval = setInterval(() => this.forgetEntries(), this.timeout) as never;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.cache.clear();
  }

  public set(id: string): void {
    this.cache.set(id, Date.now());
  }

  public has(id: string): boolean {
    return this.cache.has(id);
  }

  private forgetEntries(): void {
    const bestAfter = Date.now() - this.timeout;
    Array.from(this.cache.entries())
      .filter(([, insertionTime]) => insertionTime <= bestAfter)
      .forEach(([id]) => this.cache.delete(id));
  }
}
