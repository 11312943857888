import { Component, Input, OnInit, Optional, Self, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Fn } from '@coin/shared/util-models';
import { GbrVacationInfos } from '@coin/modules/gbr/util';
import { flyFromBottomAnim } from '../../animations/flyFromBottom.anim';

type Period = GbrVacationInfos['period'];
export enum SimpleGbrInputType {
  Text,
  Dropdown,
  DateRange,
  YesNo
}

/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({
  selector: 'coin-simple-gbr-input',
  templateUrl: './simple-gbr-input.component.html',
  styleUrls: ['./simple-gbr-input.component.scss', '../../styles/gbr-data-point.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [flyFromBottomAnim]
})
export class SimpleGbrInputComponent implements OnInit, ControlValueAccessor {
  @Input() display = false;
  @Input() labelTooltip: string;
  @Input() type: SimpleGbrInputType = SimpleGbrInputType.Text;
  @Input() isNumber: boolean;
  @Input() options: string[] = [];

  @Input() label: string;
  @Input() infoText: string;

  public types = SimpleGbrInputType;

  public disabled = false;
  public isTextDisabled = true;

  private _value: any;
  set value(val: any) {
    this._value = val;
    this.onChange(val);
  }
  get value() {
    return this._value;
  }

  get invalid(): boolean {
    if (this.type === SimpleGbrInputType.DateRange) {
      const valueAsPeriod = this.value as Period;
      return !valueAsPeriod.start || !valueAsPeriod.end || this.isDateRangeTooShort();
    }

    return this.control?.invalid;
  }

  get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched } = this.control;

    return this.invalid && (dirty || touched);
  }

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  constructor(
    @Self() @Optional() public control: NgControl,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    this.control && (this.control.valueAccessor = this);
  }

  ngOnInit() {
    if (this.type === SimpleGbrInputType.DateRange && !this.value) {
      this.writeValue({
        start: '',
        end: ''
      });
    }
  }

  private isDateRangeTooShort(): boolean {
    const valueAsPeriod = this.value as Period;
    return valueAsPeriod.start && valueAsPeriod.end && valueAsPeriod.end.diff(valueAsPeriod.start, 'days') < 6;
  }

  getDateRangeText(dateRange: { start: string; end: string }): string {
    if (!dateRange) {
      return '';
    }

    const startText = moment(dateRange.start).format('DD.MM.YYYY');
    const endText = moment(dateRange.end).format('DD.MM.YYYY');
    return `${startText}<br/>${endText}`;
  }

  getRangeValue(dateRange: { start: string; end: string }): number {
    if (!dateRange) {
      return 0;
    }

    return moment(dateRange.end).add(1, 'day').diff(moment(dateRange.start), 'week');
  }

  getGrantValue(dateRange: { start: string; end: string }): number {
    const weeks = this.getRangeValue(dateRange);
    if (weeks >= 2) {
      return 14;
    }
    if (weeks >= 1) {
      return 7;
    }
    return 0;
  }

  toggleYesNo(value: boolean) {
    this.writeValue(value);
    this.onTouch();
  }

  manualChange() {
    this.writeValue(this.value);
    if (this.isDateRangeTooShort()) {
      this.toastrService.error(this.translateService.instant('gbr.period-too-short'));
    }

    this.onTouch();
  }

  userChanges() {
    console.log('User Changes');
    this.onTouch();
  }

  writeValue(value: unknown): void {
    this.value = value;
  }
  registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
