import { v4 as uuid } from 'uuid';
import { RuleEngineConditionTypeEnum, RuleEngineOperators } from '@coin/shared/util-enums';

export interface RuleV2 {
  id: string;
  value?: string | string[];
  rules?: RuleV2[];
  condition?: RuleEngineConditionTypeEnum;
  operator?: RuleEngineOperators;
  field?: string;
  order?: number;
  level?: string;
}

export class EmptyRuleV2 implements RuleV2 {
  id = uuid();
  value = '';
}

export class EmptyGroupV2 implements RuleV2 {
  id = uuid();
  condition = RuleEngineConditionTypeEnum.Or;
  rules: RuleV2[] = [];
  level: string;
  field = '';

  constructor(_level: string) {
    this.level = _level;
  }
}
