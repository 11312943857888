export class ControlValue<T> {
  public touched = false;
  public disabled = false;
  public onChange: (value: T) => void;
  public onTouched: () => void;

  constructor(private value: T) {}

  public setInitialValue(value: T): void {
    this.value = value;
  }

  public setValue(value: T): void {
    this.value = value;
    if (!this.disabled) {
      this.onChange(value);
    }
  }

  public getValue(): T {
    return this.value;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }
}
