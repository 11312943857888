<div class="location-input-wrapper" #wrapper tabindex="-1">
  <mat-card class="mat-mdc-elevation-specific mat-elevation-z3" [class.active]="hasFocus" [class.collapsed]="data?.sameAsEmployee">
    <div class="label">{{ 'gbr.location.same-label' | translate }}</div>

    <div class="main-part" (mousedown)="toggleEdit()">
      <p class="value">{{ selectionLabel }}</p>
      <div class="icon">
        <mat-icon>{{ hasFocus ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>
    </div>

    <div class="edit-part" tabindex="-1" #editPart>
      <div class="yes-no-option">
        <div class="yes">
          <coin-checkbox [checked]="data?.sameAsEmployee" (onToggle)="onSameToggle(true)" [disabledSelfToggle]="true">
            {{ 'general.yes' | translate }}
          </coin-checkbox>
        </div>
        <div class="no">
          <coin-checkbox [checked]="!data?.sameAsEmployee" (onToggle)="onSameToggle(false)" [disabledSelfToggle]="true">
            {{ 'general.no' | translate }}
          </coin-checkbox>
        </div>
      </div>
      <div class="location-inputs" [class.active]="!data?.sameAsEmployee">
        <ng-container *ngIf="data && !data?.sameAsEmployee">
          <coin-input class="street" svgIcon="edit" [placeholder]="'gbr.entry' | translate" [label]="'gbr.location.street' | translate" addClass="shadow" [(ngModel)]="data.street">
          </coin-input>
          <coin-input class="number" svgIcon="edit" [placeholder]="'gbr.entry' | translate" [label]="'gbr.location.number' | translate" addClass="shadow" [(ngModel)]="data.number">
          </coin-input>
          <coin-input
            class="postalcode"
            svgIcon="edit"
            [placeholder]="'gbr.entry' | translate"
            [label]="'gbr.location.postalcode' | translate"
            addClass="shadow"
            [(ngModel)]="data.postalCode">
          </coin-input>
        </ng-container>
      </div>
    </div>
  </mat-card>
</div>
