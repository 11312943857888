import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GbrLocationInput } from '@coin/modules/gbr/util';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'coin-siemens-energy-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss']
})
export class LocationInputComponent {
  @Input() data: GbrLocationInput;

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('editPart') editPart: ElementRef;

  get hasFocus(): boolean {
    return !!this.editPart?.nativeElement?.matches(':focus-within');
  }

  get selectionLabel(): string {
    if (this.data?.sameAsEmployee) {
      return this.translateService.instant('general.yes');
    }

    if (this.hasFocus || !this.data?.number || !this.data?.postalCode || !this.data?.street) {
      return this.translateService.instant('gbr.location.selection-label');
    }

    return `${this.data?.street} ${this.data?.number}, ${this.data?.postalCode}`;
  }

  constructor(private translateService: TranslateService) {}

  public onSameToggle(value: boolean): void {
    this.data.sameAsEmployee = value;
  }

  public toggleEdit(): void {
    const newEdit = !this.hasFocus;
    setTimeout(() => (newEdit ? this.editPart.nativeElement.focus() : this.wrapper.nativeElement.focus()), 0);
  }
}
