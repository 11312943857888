import { trigger, transition, style, animate } from '@angular/animations';

export const flyFromBottomAnim = trigger('flyInFromBottom', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: '0' }),
    animate('0ms 400ms', style({ transform: 'translateY(100%)', opacity: '0' })),
    style({ opacity: '1' }),
    animate('400ms ease-out', style({ transform: 'translateY(0%)' }))
  ])
]);
