/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SharedRuleSetModel } from './shared-rule-set-model';

export interface OrganisationManagementRuleModel {
  id?: string;
  type?: string | null;
  title?: string | null;
  ruleSetId?: string | null;
  severity?: OrganisationManagementRuleModelSeverityEnum;
  validation?: OrganisationManagementRuleModelValidationEnum;
  message?: string | null;
  configuration?: string | null;
  isActive?: boolean;
  ruleSet?: SharedRuleSetModel;
  validFrom?: string;
  validTo?: string | null;
}
export enum OrganisationManagementRuleModelSeverityEnum {
  Error = 'Error',
  Warning = 'Warning',
  Information = 'Information'
}
export enum OrganisationManagementRuleModelValidationEnum {
  OrgNameMatchOrgCodeAbbreviation = 'OrgNameMatchOrgCodeAbbreviation',
  ProhibitedOrgCodeAbbreviation = 'ProhibitedOrgCodeAbbreviation',
  ProhibitedOrgName = 'ProhibitedOrgName',
  EmptyHeadPosition = 'EmptyHeadPosition',
  OnlyOneHeadPosition = 'OnlyOneHeadPosition',
  MaximumOrgLevel = 'MaximumOrgLevel',
  InheritancePrinciple = 'InheritancePrinciple',
  InvalidContractStatus = 'InvalidContractStatus',
  InvalidWorkforceType = 'InvalidWorkforceType'
}
