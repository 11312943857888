export enum CommunicationWorkflowType {
  EmployeeConfirmation = 'EmployeeConfirmation',
  EmployeeSignature = 'EmployeeSignature',
  ManagerConfirmationEmployeeConfirmation = 'ManagerConfirmationEmployeeConfirmation',
  ManagerConfirmationEmployeeSignature = 'ManagerConfirmationEmployeeSignature',
  ManagerSignatureEmployeeConfirmation = 'ManagerSignatureEmployeeConfirmation',
  ManagerSignatureEmployeeSignature = 'ManagerSignatureEmployeeSignature',
  NoActionNeeded = 'NoActionNeeded',
  ManagerConfirmationEmployeeNoActionNeeded = 'ManagerConfirmationEmployeeNoActionNeeded',
  ManagerConfirmationMercerValidation = 'ManagerConfirmationMercerValidation'
}
