export enum LetterState {
  None = 'None',
  Created = 'Created',
  InManagerReview = 'InManagerReview',
  InEmployeeReview = 'InEmployeeReview',
  ManagerRejected = 'ManagerRejected',
  EmployeeRejected = 'EmployeeRejected',
  Published = 'Published',
  NoLetterRequired = 'NoLetterRequired',
  NoTafRequired = 'NoTafRequired'
}
